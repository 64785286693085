import { useRef, useEffect, useContext, useState } from "react";
import { ComposeContext } from "../context/compose_tweet_context";
import { AuthContext } from "../context/auth_context";
import "react-toastify/dist/ReactToastify.css";
import {
  ScrollShadow,
  Tab,
  Tabs,
  cn,
  Tooltip,
  Spinner,
} from "@nextui-org/react";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Avatar,
  Image,
} from "@nextui-org/react";

import MessageCard from "./ai_modal/message_card";
import { Icon } from "@iconify/react";

import PromptInput from "./ai_modal/prompt_input";

const AiModal = () => {
  const { account } = useContext(AuthContext);

  const cancelButtonRef = useRef(null);
  const ref = useRef(null);
  const [isFailed, setIsFailed] = useState(false);

  const {
    setTweet,
    aiModal,
    setAiModal,
    aiTweet,
    setAiTweet,
    loading,
    setLoading,
    retry,
    setRetry,
    conversation,
    setConversation,
    setCancel,
    isRegenerate,
    setRegClicked,
  } = useContext(ComposeContext);

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.ctrlKey) {
      event.preventDefault(); // Prevents the default behavior of adding a newline
      if (aiTweet) {
        handleRetry(); // Trigger the submission logic
      }
    }
  };

  useEffect(() => {
    if (conversation.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [conversation.length, loading]);

  return (
    <>
      <Modal
        isOpen={aiModal}
        onClose={() => {
          setAiTweet("");
          setConversation([]);
          setAiModal(false);
          setLoading(true);
          setCancel(true);
        }}
        size="2xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {loading
                  ? "Your AI assistant is working on it!"
                  : "Your AI assistant response!"}

                <div className="flex items-center gap-1">
                  <Image src="/gpt.png" width={25} alt="GPT-4 Logo" />
                  <span className="text-xs font-medium">Powered by GPT-4</span>
                </div>
              </ModalHeader>
              <ModalBody>
                <ScrollShadow className={cn("flex h-[400px] flex-col")}>
                  <div className="flex flex-col gap-4 px-1">
                    {conversation.map((chat, index) => (
                      <MessageCard
                        key={index}
                        attempts={index === 1 ? 2 : 1}
                        avatar={
                          chat?.role === "assistant"
                            ? "/bot.jpg"
                            : account?.data?.profile_pic
                        }
                        currentAttempt={index === 1 ? 2 : 1}
                        message={
                          chat.role === "assistant" &&
                          !loading &&
                          index === conversation.length - 1
                            ? chat?.content
                            : chat?.content
                        }
                        messageClassName={
                          chat?.role === "user"
                            ? "bg-content3 text-content3-foreground"
                            : ""
                        }
                        showFeedback={chat?.role === "assistant"}
                      />
                    ))}
                    {loading && (
                      <div className={`flex items-start gap-2.5 mb-4`}>
                        <div className="flex flex-col flex-grow ">
                          <Spinner size="sm" />
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollShadow>
                <div className="flex flex-col gap-2 mt-2 mb-2">
                  <div className="flex w-full flex-col gap-4">
                    {isRegenerate && (
                      <div>
                        <Button
                          // isDisabled={isRegenerating}
                          size="sm"
                          startContent={
                            <Icon
                              className={cn("text-medium origin-center")}
                              icon="solar:restart-linear"
                            />
                          }
                          variant="flat"
                          onPress={() => setRegClicked(true)}
                        >
                          Regenerate
                        </Button>
                      </div>
                    )}
                    <form
                      className="flex w-full flex-col items-start rounded-medium bg-default-100 transition-colors hover:bg-default-200/70"
                      onKeyDown={handleKeyDown}
                    >
                      <PromptInput
                        classNames={{
                          inputWrapper: "!bg-transparent shadow-none",
                          innerWrapper: "relative",
                          input: "pt-1 pl-2 pb-6 !pr-10",
                        }}
                        endContent={
                          <div className="flex items-end gap-2">
                            <Tooltip showArrow content="Send message">
                              <Button
                                isLoading={loading}
                                isIconOnly
                                color={!aiTweet ? "default" : "primary"}
                                isDisabled={!aiTweet}
                                radius="lg"
                                size="sm"
                                variant="solid"
                                onClick={handleRetry}
                                ref={cancelButtonRef}
                              >
                                <Icon
                                  className={cn(
                                    "[&>path]:stroke-[2px]",
                                    !aiTweet
                                      ? "text-default-600"
                                      : "text-primary-foreground"
                                  )}
                                  icon="solar:arrow-up-linear"
                                  width={20}
                                />
                              </Button>
                            </Tooltip>
                          </div>
                        }
                        minRows={3}
                        radius="lg"
                        value={aiTweet}
                        variant="flat"
                        onValueChange={setAiTweet}
                      />
                      <small className="text-xs text-default-500 p-2">
                        Press <strong>Ctrl + Enter</strong> to submit
                      </small>
                    </form>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AiModal;
