import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../context/auth_context";

import { backend_url } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Avatar,
  Spacer,
  Code,
  ScrollShadow,
  cn,
  Tooltip,
} from "@nextui-org/react";

import { Icon } from "@iconify/react";
import ReactGiphySearchbox from "react-giphy-searchbox";

const MediaUploadModal = ({
  isUploadModal,
  setIsUploadModal,
  handleFileUpload,
  fileInputRef,
  isGifDisabled,
  handleSelectGif,
}) => {
  const [isGifSelect, setIsGifSelect] = useState(false);

  useEffect(() => {
    if (!isUploadModal) {
      setIsGifSelect(false);
    }
  }, [isUploadModal]);

  return (
    <>
      <Modal
        isOpen={isUploadModal}
        onOpenChange={(open) => setIsUploadModal(open)}
        size="lg"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <div className="text-medium">Upload Media</div>
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col items-center gap-4">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <Icon
                        icon="f7:cloud-upload"
                        width={30}
                        className="text-default-500"
                      />
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                      accept="image/*,video/*"
                    />
                  </label>
                </div>
                <div className="text-sm mx-auto text-gray-500 dark:text-gray-400">
                  OR
                </div>

                <div className="flex justify-center">
                  <Tooltip
                    content={
                      isGifDisabled
                        ? "You can only choose 1 GIF, 1 video, or up to 4 images"
                        : "Select a GIF"
                    }
                    placement="top"
                  >
                    <Button
                      color="success"
                      variant="bordered"
                      size="sm"
                      disabled={isGifDisabled}
                      endContent={
                        <Icon icon="teenyicons:gif-outline" width={20} />
                      }
                      onClick={() =>
                        !isGifDisabled && setIsGifSelect(!isGifSelect)
                      }
                    >
                      Select a GIF
                    </Button>
                  </Tooltip>
                </div>
                {isGifSelect && (
                  <div className="flex justify-center">
                    <ReactGiphySearchbox
                      apiKey="zpgHg1e3m6vBwiwgYAEBdUJnhWjuC7eA" // Required: get your on https://developers.giphy.com
                      onSelect={handleSelectGif}
                      columns={3}
                      masonryConfig={[
                        { columns: 4, imageWidth: 110, gutter: 5 },
                      ]}
                    />
                  </div>
                )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default MediaUploadModal;
