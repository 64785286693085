import gqt from "graphql-tag";

export const GetUserByPk = gqt`query GetUserByPk($id: String!) {
  user_by_pk(id: $id) {
    name
    email
    plan_id
    free_trial_ends_on
    twitter_accounts {
      id
      profile_pic
      status
      username
      me
      user_preferences
    }
    plan {
      name
      is_tweet_engage
      is_crm
      is_ai_tweet_assistant
      is_tweet_ideas
    }
  }
}`;

export const UpdateUser = gqt`
mutation UpdateUser($user_id: String!, $object: user_set_input = {}) {
  update_user_by_pk(pk_columns: {id: $user_id}, _set: $object) {
    id
  }
}
`;

export const UpdateTwitterAccount = gqt`
mutation UpdateTwitterAccount($user_id: String, $object: twitter_account_set_input = {}) {
  update_twitter_account(where: {user_id: {_eq: $user_id}}, _set: $object) {
    affected_rows
  }
}
`;

export const GetUserAccounts = gqt`query GetUserAccounts($user_id: String) {
  twitter_account(where: {user_id: {_eq: $user_id}}) {
    id
    username
    profile_pic
    status
    me
    user {
      plan_id
    }
  }
}`;

export const CreateCollection = gqt`mutation CreateCollection($object: collection_insert_input = {}) {
  insert_collection_one(object: $object) {
    id
  }
}
`;

export const GetCollections = gqt`query GetCollections($account_id: uuid) {
  collection(where: {twitter_account_id: {_eq: $account_id}}) {
    id
    name
  }
}`;

export const UpdateCollectionByPk = gqt`mutation UpdateCollectionByPk($id: uuid!, $_set: collection_set_input = {}) {
  update_collection_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}`;

export const DeleteCollectionByPk = gqt`mutation DeleteCollectionByPk($id: uuid!) {
  delete_collection_by_pk(id: $id) {
    id
  }
}`;

export const DeleteSavedTweetByCollectionId = gqt`mutation DeleteSavedTweetByCollectionId($collection_id: uuid) {
  delete_saved_tweet(where: {collection_id: {_eq: $collection_id}}) {
    affected_rows
  }
}`;

export const CreateList = gqt`mutation CreateList($object: list_insert_input = {}) {
  insert_list_one(object: $object) {
    id
  }
}`;

export const GetListByPk = gqt`query GetListByPk($id: uuid!) {
  list_by_pk(id: $id) {
    name
    people {
      id
      account_data
      username
    }
  }
}`;

export const DeleteListByPk = gqt`mutation DeleteListByPk($id: uuid!) {
  delete_list_by_pk(id: $id) {
    id
  }
}`;

export const DeletePeopleByPk = gqt`mutation DeletePeopleByPk($id: uuid!) {
  delete_people_by_pk(id: $id) {
    id
  }
}`;

export const GetLists = gqt`query GetLists($account_id: uuid) {
  list(where: {twitter_account_id: {_eq: $account_id}}) {
    id
    name
    color
    people_aggregate {
      aggregate {
        count
      }
    }
    people {
      username
    }
  }
}`;

export const UpdateListByPk = gqt`mutation UpdateListByPk($id: uuid = "", $_set: list_set_input = {}) {
  update_list_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}`;

export const CreateSavedTweet = gqt`mutation CreateSavedTweet($object: saved_tweet_insert_input = {}) {
  insert_saved_tweet_one(object: $object) {
    id
  }
}`;

export const GetSavedTweets = gqt`query GetSavedTweets($account_id: uuid) {
  saved_tweet(where: {twitter_account_id: {_eq: $account_id}}, order_by: {created_at: desc}) {
    id
    collection_id
    tweet_data
  }
}`;

export const GetAIAssistents = gqt`query GetAIAssistents {
  ai_assistent(order_by: {order: asc}) {
    id
    name
    query
    icon
  }
}`;

export const GetTweets = gqt`query GetTweets($account_id: uuid, $first_day: timestamptz, $last_day: timestamptz) {
  tweet(order_by: {scheduled_at: desc}, where: {status: {_eq: "pending"}, twitter_account_id: {_eq: $account_id}, scheduled_at: {_gte: $first_day, _lte: $last_day}}) {
    id
    text
    scheduled_at
    twitter_account_id
    scheduled_event_id
    twitter_account {
      profile_pic
      username
      me
    }
  }
}`;

export const UpdateTweetByPk = gqt`mutation UpdateTweetByPk($id: uuid!, $_set: tweet_set_input = {}) {
  update_tweet_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}`;

export const GetPostedTweets = gqt`query GetPostedTweets($account_id: uuid) {
  tweet(where: {twitter_account_id: {_eq: $account_id}, status: {_eq: "posted"}}, order_by: {scheduled_at: desc}) {
    id
    text
    scheduled_at
    twitter_account_id
    tweet_id
    twitter_account {
      profile_pic
      username
      me
    }
  }
}`;

export const GetAccountByPk = gqt`query GetAccountByPk($id: uuid!) {
  twitter_account_by_pk(id: $id) {
    user_preferences
    username
    me
    tweets(order_by: {created_at: desc}, limit: 10) {
      text
    }
  }
}`;

export const GetTweetIdeasCategory = gqt`
query GetTweetIdeas($limit: Int!, $offset: Int!, $category: String) {
  tweet_ideas(limit: $limit, offset: $offset, where: {category: {_eq: $category}}, order_by: {id: asc}) {
    id
    category
    tweet_data
  }
}
`;

export const GetTweetIdeas = gqt`
query GetTweetIdeas($limit: Int!, $offset: Int!) {
  tweet_ideas(limit: $limit, offset: $offset,order_by: {id: asc}) {
    id
    category
    tweet_data
  }
}
`;

export const GetPlans = gqt`
query GetPlans {
  plan(order_by: {order: asc}) {
    id
    name
    description
    price
    features
    extra
    most_popular
    original_price
  }
}`;

export const UpdateAccountByPk = gqt`mutation UpdateAccountByPk($id: uuid!, $_set: twitter_account_set_input = {}) {
  update_twitter_account_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}`;

export const DeleteAccountByPk = gqt`mutation DeleteAccountByPk($id: uuid!) {
  delete_twitter_account_by_pk(id: $id) {
    id
  }
}`;

export const GetTweetTemplates = gqt`query GetTweetTemplates {
  tweet_template {
    id
    title
    template
    category_id
  }
}`;

export const GetTweetCategory = gqt`query GetTweetCategory {
  template_category {
    id
    name
  }
}`;
