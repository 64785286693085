import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LoaderCircleIcon } from "../icons";

import { ComposeContext } from "../context/compose_tweet_context";
import { GetUserByPk, GetAIAssistents } from "../graphql";
import { useQuery } from "urql";
import { AuthContext } from "../context/auth_context";
import { backend_url } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TweetConfirmation from "./tweet_confirmation";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import EmojiPicker from "emoji-picker-react";

import PricingModal from "./pricing_modal";

import { toUnicodeVariant } from "./utils/unicode_varient";
import AdvancedOptions from "./advanced_options";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Spacer,
  Button,
  Accordion,
  AccordionItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Chip,
  Image,
  Tooltip,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

import ReactGiphySearchbox from "react-giphy-searchbox";
import MediaUploadModal from "./media_upload_modal";

function ComposeTweet({ close }) {
  const navigate = useNavigate();

  const textareaRef = useRef(null);

  const {
    setTweet,
    tweet,
    setAiModal,
    aiTweet,
    setAiTweet,
    loading,
    setLoading,
    retry,
    setRetry,
    setConversation,
    cancel,
    setCancel,
    preview,
    setPreview,
    limitWarning,
    setLimitWarning,
    isThread,
    setIsThread,
    isRegenerate,
    setIsRegenerate,
    regClicked,
    setRegClicked,
    mediaPreview,
    setMediaPreview,
    threadMedia,
    setThreadMedia,
    selectedMedia,
    setSelectedMedia,
  } = useContext(ComposeContext);

  useEffect(() => {
    // Focus on the textarea when the component mounts
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const fileInputRef = useRef(null);

  const { auth, account } = useContext(AuthContext);
  dayjs.extend(utc);

  const [ai, setAi] = useState(false);
  const [assistents, setAssistents] = useState([]);
  const [tmpAssistId, setTmpAssistId] = useState("");
  const [isTweeting, setIsTweeting] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );

  const [isScheduling, setIsScheduling] = useState(false);
  const [tweetHistory, setTweetHistory] = useState([]);
  const [isEmoji, setIsEmoji] = useState(false);
  const [tmpTweet, setTmpTweet] = useState("");
  const [extraStyles, setExtraStyles] = useState([]);
  const [isRichText, setIsRichText] = useState(false);
  const [isGifSelect, setIsGifSelect] = useState(false);
  // const [selectedMedia, setSelectedMedia] = useState([]);
  const [textStyling, setTextStyling] = useState("");
  const [isStyleDropdown, setIsStyleDropdown] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isPricing, setIsPricing] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [isThreadDelay, setIsThreadDelay] = useState(false);
  const [selectedThreadDelay, setSelectedThreadDelay] = useState("10s");
  const [isAutoRetweet, setIsAutoRetweet] = useState(false);
  const [selectedRetweetInterval, setSelectedRetweetInterval] = useState(1);
  const [selectedRetweetTimes, setSelectedRetweetTimes] = useState("1");
  const [isAutoPlug, setIsAutoPlug] = useState(false);
  const [plugLikes, setPlugLikes] = useState(20);
  const [plugMessage, setPlugMessage] = useState("");
  const [isAutoDm, setIsAutoDm] = useState(false);
  const [dmMessage, setDmMessage] = useState("");
  const [dmLike, setDmLike] = useState(false);
  const [dmRetweet, setDmRetweet] = useState(false);
  const [dmReply, setDmReply] = useState(false);

  const [isAssistant, setIsAssistant] = useState(false);
  const [isFirework, setIsFirework] = useState(false);
  // const [mediaPreview, setMediaPreview] = useState([]);
  const [isGifAvailable, setIsGifAvailable] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);

  // useEffect(() => {
  //   setTmpTweet(tweet);
  // }, [tweet]);

  const [resultUser] = useQuery({
    query: GetUserByPk,
    variables: {
      id: auth?.data?.id,
    },
  });

  const { data: dataUser } = resultUser;

  useEffect(() => {
    if (dataUser?.user_by_pk) {
      console.log("dataUser?.user_by_pk", dataUser?.user_by_pk);
      const freeTrialEndsOn = dataUser?.user_by_pk?.free_trial_ends_on;
      const planId = dataUser?.user_by_pk?.plan_id;

      const freeTrialEndsDate = new Date(freeTrialEndsOn);
      const currentDate = new Date();

      if (planId) {
        setCurrentPlan(dataUser?.user_by_pk?.plan_id);
      } else if (freeTrialEndsDate > currentDate) {
        setCurrentPlan("trial");
      }
    }
  }, [dataUser]);

  const [result] = useQuery({
    query: GetAIAssistents,
  });

  const { data, fetching } = result;

  useEffect(() => {
    if (data?.ai_assistent?.length > 0) {
      setAssistents(data?.ai_assistent);
    }
  }, [data]);

  const MAX_CHARACTERS = 280;

  useEffect(() => {
    if (retry > 0) {
      setLoading(true);
      handleAssistent(tmpAssistId, true);
      setRetry(0);
    }
  }, [retry]);

  useEffect(() => {
    if (regClicked === true) {
      setLoading(true);
      console.log("regenerate");
      handleAssistentRegenerate();
      setRegClicked(false);
      setIsRegenerate(false);
    }
  }, [regClicked]);

  useEffect(() => {
    if (cancel) {
      setTweetHistory([]);
    }
  }, [cancel]);

  const handleAssistent = async (id, retry) => {
    console.log("currentPlan", currentPlan);
    if (!currentPlan) {
      setIsPricing(true);
      return;
    }
    setIsAssistant(false);
    setCancel(false);
    if (!account?.data?.id) {
      toast.error("Select a account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }

    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    const toPostTweet = retry ? aiTweet : tweet;
    setAiModal(true);
    setTmpAssistId(id);
    try {
      setAiTweet("");

      const conversation = tweetHistory;

      conversation.push({ role: "user", content: toPostTweet });
      setConversation(conversation);

      const response = await fetch(`${backend_url}/openai/generate-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          assist_id: id,
          original_tweet: toPostTweet,
          conversation,
        }),
      });

      if (!response.ok) {
        console.log("res failed");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result?.data);
      if (result?.message === "success") {
        const responseTweet =
          result?.data?.tweets?.tweet ||
          result?.data?.tweets?.[0]?.tweet ||
          result?.data?.tweets;
        conversation.push({ role: "assistant", content: responseTweet });
        setTweetHistory(conversation);
        setConversation(conversation);
        setAi(!ai);
      } else if (result?.message === "exceeded") {
        toast.error("Daily query limit exceeded. Please try again tomorrow.", {
          autoClose: 5000,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // toast.error("Something went wrong, please try again", {
      //   autoClose: 5000,
      // });
      setLoading(false);
      // setAiModal(false);
      // setAiTweet("");

      const conversation = tweetHistory;

      conversation.push({ role: "assistant", content: "failed" });
      setConversation(conversation);
      setIsRegenerate(true);
    }
  };

  const handleAssistentRegenerate = async () => {
    try {
      const tmpConveration = tweetHistory;
      tmpConveration.pop();
      const conversation = tmpConveration;

      console.log("converstation", conversation);

      const response = await fetch(`${backend_url}/openai/generate-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          assist_id: tmpAssistId,
          original_tweet: "",
          conversation,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result?.data);

      if (result?.message === "success") {
        const responseTweet =
          result?.data?.tweets?.tweet ||
          result?.data?.tweets?.[0]?.tweet ||
          result?.data?.tweets;
        conversation.push({ role: "assistant", content: responseTweet });
        setTweetHistory(conversation);
        setConversation(conversation);
        setAi(!ai);
        setAi(!ai);
      } else if (result?.message === "exceeded") {
        toast.error("Daily query limit exceeded. Please try again tomorrow.", {
          autoClose: 5000,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // toast.error("Something went wrong, please try again", {
      //   autoClose: 5000,
      // });
      setLoading(false);
      // setAiModal(false);
      // setAiTweet("");

      const conversation = tweetHistory;

      conversation.push({ role: "assistant", content: "failed" });
      setConversation(conversation);
      setIsRegenerate(true);
    }
  };

  // const handleTextareaChange = (content, delta, source, editor) => {
  //   setTweet(editor.getHTML());
  // };

  const handleTextareaChange = (e) => {
    const newText = e.target.value;
    // if (newText != "") {
    //   setPreview(true);
    // } else {
    //   setPreview(false);
    // }

    setTweet(newText);
    setTmpTweet(newText);
  };

  const handleTweetNow = async () => {
    if (!currentPlan) {
      setIsPricing(true);
      return;
    }

    if (!account?.data?.id) {
      toast.error("Select a account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }

    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    setIsScheduled(false);
    setConfirmation(true);
  };

  const uploadToS3 = async (file, presignedUrl) => {
    const response = await fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (!response.ok) {
      throw new Error("Upload failed");
    }

    return presignedUrl.split("?")[0];
  };

  const getPresignedUrl = async (fileName, type) => {
    const response = await fetch(
      `${backend_url}/tweets/generate-presigned-url`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileName: fileName,
          type: type,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result);
    if (result?.message === "success") {
      return result?.data;
    }
  };

  const resetForm = () => {
    setTweet("");
    setPreview(false);
    setIsThread(false);
    setIsThreadDelay(false);
    setSelectedThreadDelay("10s");
    setSelectedMedia([]);
    setThreadMedia([]);
    setMediaPreview([]);
    setIsAutoPlug(false);
    setPlugLikes(20);
    setPlugMessage("");
    setIsAutoDm(false);
    setDmMessage("");
    setDmLike(false);
    setDmRetweet(false);
    setDmReply(false);
    setIsAdvanced(false);
  };

  const confirmTweetNow = async () => {
    console.log("main media", selectedMedia);
    console.log("thread media", threadMedia);

    console.log("is retweet", isAutoRetweet);
    console.log("interval", selectedRetweetInterval);
    console.log("times", selectedRetweetTimes);

    setConfirmation(false);

    setIsTweeting(true);

    const mediaFiles = await Promise.all(
      selectedMedia.map(async (media) => {
        console.log("media", media);
        if (typeof media === "string" && media.startsWith("http")) {
          console.log("Media is a URL");

          return media;
        } else if (media) {
          console.log("media is a file");

          // Generate pre-signed URL
          const fileName = `${Date.now()}_${media.name}`;
          console.log("file name", fileName);
          const presignedUrl = await getPresignedUrl(fileName, media?.type);
          console.log("presigned url", presignedUrl);
          // Upload the file to S3
          const s3Url = await uploadToS3(media, presignedUrl);
          console.log("s3 url", s3Url);
          return s3Url;
        }
      })
    );

    console.log("media files", mediaFiles);

    // Upload thread media
    const threadMediaFiles = await Promise.all(
      threadMedia.map(async (mediaArray, threadIndex) => {
        if (mediaArray && mediaArray.length > 0) {
          const uploadedThreadMedia = await Promise.all(
            mediaArray.map(async (media) => {
              if (typeof media === "string" && media.startsWith("http")) {
                return media;
              } else if (media) {
                const fileName = `${Date.now()}_${media.name}`;
                const presignedUrl = await getPresignedUrl(
                  fileName,
                  media?.type
                );
                const s3Url = await uploadToS3(media, presignedUrl);
                return s3Url;
              }
            })
          );
          return { threadIndex, media: uploadedThreadMedia };
        }
        return null;
      })
    );

    console.log("thread media files", threadMediaFiles);

    // Filter out empty thread media arrays
    const filteredThreadMediaFiles = threadMediaFiles.filter(Boolean);

    try {
      const response = await fetch(`${backend_url}/tweets/post-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          tweet: tweet.trim(),
          media: mediaFiles,
          threadMedia: filteredThreadMediaFiles || [],
          isThreadDelay: isThreadDelay,
          selectedThreadDelay: selectedThreadDelay,
          isAutoRetweet: isAutoRetweet,
          selectedRetweetInterval: Number(selectedRetweetInterval),
          selectedRetweetTimes: Number(selectedRetweetTimes),
          isAutoPlug: isAutoPlug,
          plugLikes: Number(plugLikes),
          plugMessage: plugMessage,
          isAutoDm: isAutoDm,
          dmMessage: dmMessage,
          dmLike: dmLike,
          dmRetweet: dmRetweet,
          dmReply: dmReply,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result?.message === "success") {
        toast.success("Tweet posted successfully!", {
          autoClose: 5000,
        });

        setIsFirework(true);

        setTimeout(() => {
          setIsFirework(false);
        }, 1500);

        if (isAutoDm) {
          //if auto dm is enabled create a alarm in extension
          const data = {
            type: "CREATE_ALARM_FOR_AUTO_DM",
          };
          window.postMessage(data, "*");

          const dmHandler = function (event) {
            // We only accept messages from ourselves
            if (event.source !== window) return;
          };

          // Add the event listener
          window.addEventListener("message", dmHandler);

          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener("message", dmHandler);
          };
        }

        resetForm();
      } else if (result?.message === "reconnect-account") {
        toast.error(
          "Something went wrong or Profile expired. Please reconnect your profile.",
          {
            autoClose: 5000,
          }
        );
        setTimeout(() => {
          navigate("/settings");
        }, 4000);
      } else {
        toast.error(result?.message, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "Something went wrong or Profile expired. Please reconnect your profile.",
        {
          autoClose: 5000,
        }
      );
    } finally {
      setIsTweeting(false);
    }
  };

  // const intercom_works = async () => {
  //   const response = await fetch(`${backend_url}/user/delete-intercom`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       user_token: auth?.data?.jwt,
  //     }),
  //   });

  //   if (!response.ok) {
  //     throw new Error(`HTTP error! Status: ${response.status}`);
  //   }
  // };

  // useEffect(() => {
  //   intercom_works();
  // }, []);

  const handleScheduleTweet = () => {
    if (!currentPlan) {
      setIsPricing(true);
      return;
    }

    if (!account?.data?.id) {
      toast.error("Select a account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }

    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    setIsScheduled(true);
    setConfirmation(true);
  };

  const confirmScheduleTweet = async () => {
    console.log("scheduled time", scheduledDate);

    const isFutureDateTime = scheduledDate > new Date();

    if (!isFutureDateTime) {
      toast.error("Please select a future date and time.", {
        autoClose: 5000,
      });
    }

    setConfirmation(false);
    setIsScheduled(false);
    setIsScheduling(true);

    const mediaFiles = await Promise.all(
      selectedMedia.map(async (media) => {
        console.log("media", media);
        if (typeof media === "string" && media.startsWith("http")) {
          console.log("Media is a URL");

          return media;
        } else if (media) {
          console.log("media is a file");
          // Generate pre-signed URL
          const fileName = `${Date.now()}_${media.name}`;
          console.log("file name", fileName);
          const presignedUrl = await getPresignedUrl(fileName, media?.type);
          console.log("presigned url", presignedUrl);
          // Upload the file to S3
          const s3Url = await uploadToS3(media, presignedUrl);
          console.log("s3 url", s3Url);

          // // Upload the file to S3
          // const s3Url = await uploadToS3(media);
          // console.log("s3 url", s3Url);

          return s3Url;
        }
      })
    );

    // Upload thread media
    const threadMediaFiles = await Promise.all(
      threadMedia.map(async (mediaArray, threadIndex) => {
        if (mediaArray && mediaArray.length > 0) {
          const uploadedThreadMedia = await Promise.all(
            mediaArray.map(async (media) => {
              if (typeof media === "string" && media.startsWith("http")) {
                return media;
              } else if (media) {
                const fileName = `${Date.now()}_${media.name}`;
                const presignedUrl = await getPresignedUrl(
                  fileName,
                  media?.type
                );
                const s3Url = await uploadToS3(media, presignedUrl);
                return s3Url;
              }
            })
          );
          return { threadIndex, media: uploadedThreadMedia };
        }
        return null;
      })
    );

    console.log("thread media files", threadMediaFiles);

    // Filter out empty thread media arrays
    const filteredThreadMediaFiles = threadMediaFiles.filter(Boolean);

    try {
      const response = await fetch(`${backend_url}/tweets/schedule-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scheduled_at: scheduledDate,
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          tweet: tweet.trim(),
          media: mediaFiles,
          threadMedia: filteredThreadMediaFiles || [],
          isThreadDelay: isThreadDelay,
          selectedThreadDelay: selectedThreadDelay,
          isAutoRetweet: isAutoRetweet,
          selectedRetweetInterval: Number(selectedRetweetInterval),
          selectedRetweetTimes: Number(selectedRetweetTimes),
          isAutoPlug: isAutoPlug,
          plugLikes: Number(plugLikes),
          plugMessage: plugMessage,
          isAutoDm: isAutoDm,
          dmMessage: dmMessage,
          dmLike: dmLike,
          dmRetweet: dmRetweet,
          dmReply: dmReply,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result?.message === "success") {
        toast.success("Tweet scheduled successfully", {
          autoClose: 5000,
        });
        resetForm();
      } else {
        toast.error("Something went wrong, please try again", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong, please try again", {
        autoClose: 5000,
      });
    } finally {
      setIsScheduling(false);
    }
  };

  const handleEmojiClick = (emojiObject, event) => {
    const { emoji } = emojiObject;

    const cursorPos = textareaRef.current.selectionStart;

    // Split the tweet into two parts at the cursor position
    const firstPart = tweet.substring(0, cursorPos);
    const secondPart = tweet.substring(cursorPos);

    const updatedTweet = firstPart + emoji + secondPart;

    setTweet(updatedTweet);

    // Close the emoji picker
    setIsEmoji(false);
  };

  const handleStyleClick = (style, extra = []) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    let selectedText = tweet.substring(start, end);
    let updatedStyles = textStyling;

    let converted = null;
    if (style) {
      if (style === updatedStyles) {
        updatedStyles = ""; // Remove the style if it exists
        converted = selectedText.normalize("NFKC");
      } else {
        selectedText = selectedText.normalize("NFKC");
        updatedStyles = style; // Add the style if it doesn't exist
      }
      setTextStyling(updatedStyles);
    }

    if (!converted) {
      converted = toUnicodeVariant(selectedText, updatedStyles, extra);
    }
    console.log("converted", converted);
    // Apply all active styles to the selected text

    setTweet(tweet.substring(0, start) + converted + tweet.substring(end));

    const newStart = start;
    const newEnd = newStart + converted.length;

    // Set a timeout to ensure that the textarea is updated before setting the selection range
    setTimeout(() => {
      // Set the selection range to keep the text selected
      textarea.setSelectionRange(newStart, newEnd);
      textarea.focus(); // Ensure textarea is focused after setting selection range
    }, 0);
    setIsStyleDropdown(false);
  };

  const handleUnderlineClick = () => {
    const tmpExtra = extraStyles;

    const styleIndex = tmpExtra.indexOf("underline");
    if (styleIndex !== -1) {
      tmpExtra.splice(styleIndex, 1);
    } else {
      tmpExtra.push("underline");
    }
    setExtraStyles(tmpExtra);

    handleStyleClick(null, tmpExtra);
  };

  const handleStrikeClick = () => {
    const tmpExtra = extraStyles;

    const styleIndex = tmpExtra.indexOf("strike");
    if (styleIndex !== -1) {
      tmpExtra.splice(styleIndex, 1);
    } else {
      tmpExtra.push("strike");
    }
    setExtraStyles(tmpExtra);
    handleStyleClick(null, tmpExtra);
  };

  const handleSelectGif = async (gifObject) => {
    const gifUrl = gifObject.images.original.url;
    console.log("gifUrl", gifUrl);
    setSelectedMedia((prev) => [...prev, gifUrl]);
    const gifPreview = {
      type: "image", // or 'gif' if you want to be specific
      url: gifUrl,
    };

    setMediaPreview((prev) => [...prev, gifPreview]);
    setIsGifAvailable(true);

    // setSelectedMedia([...selectedMedia, gifUrl]);

    // try {
    //   const response = await fetch(gifUrl);
    //   if (!response.ok) {
    //     throw new Error(`Failed to fetch GIF from URL: ${gifUrl}`);
    //   }
    //   const blob = await response.blob();
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const base64Data = reader.result; // Base64 data of the GIF
    //     setSelectedMedia([...selectedMedia, base64Data]);
    //   };
    //   reader.readAsDataURL(blob);
    // } catch (error) {
    //   console.error(error);
    //   // Handle error
    // }
    setIsGifSelect(false);
    setIsUploadModal(false);
  };

  const handleFileUpload = (event) => {
    console.log("file changed");
    setIsUploadModal(false);
    const files = Array.from(event.target.files);
    console.log("selectedMedia", selectedMedia);
    let photoCount = selectedMedia.filter(
      (media) =>
        media?.type?.startsWith("image/") && media?.type !== "image/gif"
    ).length;

    let videoCount = selectedMedia.filter((media) =>
      media?.type?.startsWith("video/")
    ).length;

    let gifCount = selectedMedia.filter(
      (media) =>
        typeof media === "string" &&
        media.startsWith("http") &&
        media.endsWith(".gif")
    ).length;

    console.log("gif length", gifCount);

    for (let file of files) {
      if (file.type.startsWith("image/") && file.type !== "image/gif") {
        if (gifCount > 0 || videoCount > 0) {
          toast.error("You can't upload photos with a GIF or video.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        if (photoCount >= 4) {
          toast.error("You can only upload up to 4 photos.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        photoCount++;
      } else if (file.type === "image/gif") {
        if (photoCount > 0 || videoCount > 0) {
          toast.error("You can't upload a GIF with photos or video.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        if (gifCount >= 1) {
          setIsGifAvailable(true);

          toast.error("You can only upload 1 GIF.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        gifCount++;
      } else if (file.type.startsWith("video/")) {
        if (photoCount > 0 || gifCount > 0) {
          toast.error("You can't upload a video with photos or a GIF.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        if (videoCount >= 1) {
          toast.error("You can only upload 1 video.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        videoCount++;
      } else {
        toast.error("Unsupported media type.", {
          autoClose: 5000,
        });
        event.target.value = null;

        return;
      }
    }
    setSelectedMedia((prevSelectedMedia) => [...prevSelectedMedia, ...files]);

    const filePreviews = files.map((file) => ({
      type: file.type.startsWith("video") ? "video" : "image",
      url: URL.createObjectURL(file),
    }));

    setMediaPreview((prevMediaPreview) => [
      ...prevMediaPreview,
      ...filePreviews,
    ]);

    event.target.value = null;
  };

  const deleteMedia = (index) => {
    // Remove from selectedMedia
    const updatedMedia = selectedMedia.filter((_, i) => i !== index);
    setSelectedMedia(updatedMedia);

    // Remove from mediaPreview
    const updatedPreview = mediaPreview.filter((_, i) => i !== index);
    setMediaPreview(updatedPreview);
  };

  useEffect(() => {
    const threads = tweet.split(/\n\s*\n\s*\n/);
    for (let i = 0; i < threads.length; i++) {
      const tweet = threads[i];
      if (tweet.length > MAX_CHARACTERS && !account?.data?.is_blue_tick) {
        setLimitWarning(true);
        if (!isErrorShown) {
          toast.error(
            "Twitter API doesn't support 280+ Characters turn this into a thread",
            { autoClose: 5000 }
          );
          setIsErrorShown(true);
        }
        break;
      } else {
        setLimitWarning(false);
      }
    }
    if (threads.length > 1) {
      setIsThread(true);
      // setPreview(true);
    } else {
      setIsThread(false);
      // setPreview(false);
    }
    if (tweet) {
      setPreview(true);
    } else {
      setPreview(false);
    }
  }, [tweet]);

  // Determine if the GIF selector should be disabled
  const isGifDisabled = selectedMedia.some(
    (media) =>
      media?.type?.startsWith("image/") || media?.type?.startsWith("video/")
  );

  // Determine if the image/video selector should be disabled
  const isMediaDisabled = selectedMedia.some(
    (media) => typeof media === "string" && media.startsWith("http")
  );

  const MemoizedTweetConfirmation = React.memo(TweetConfirmation);
  const MemoizedPricingModal = React.memo(PricingModal);

  return (
    <>
      {isFirework && <Fireworks autorun={{ speed: 5 }} />}
      <MemoizedPricingModal isPricing={isPricing} setIsPricing={setIsPricing} />
      <MemoizedTweetConfirmation
        isConfirmation={isConfirmation}
        setConfirmation={setConfirmation}
        tweet={tweet}
        isScheduled={isScheduled}
        confirmTweetNow={confirmTweetNow}
        confirmScheduleTweet={confirmScheduleTweet}
        scheduledDate={scheduledDate}
        setScheduledDate={setScheduledDate}
      />
      <MediaUploadModal
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        handleFileUpload={handleFileUpload}
        fileInputRef={fileInputRef}
        isGifDisabled={isGifDisabled}
        handleSelectGif={handleSelectGif}
      />
      <div className="h-full">
        <ToastContainer />
        <Card
          className="max-w-[400px] h-screen bg-gradient-to-r from-violet-300/50 to-purple-100/50 dark:from-violet-300/20 dark:to-purple-100/20"
          isBlurred
          shadow="sm"
        >
          <CardHeader className="flex gap-3">
            <Icon icon="fluent:compose-12-regular" width={36} />
            <div className="flex flex-col">
              <p className="text-sm">Compose Tweet</p>
              <p className="text-xs text-default-500">
                Begin crafting your tweet
              </p>
            </div>
          </CardHeader>
          <Divider />
          <CardBody>
            <div>
              <textarea
                ref={textareaRef}
                className="border h-72 text-xs rounded-xl border-gray-300 w-full p-3 bg-gray-100 text-default-600 focus:outline-none dark:border-gray-600 dark:bg-zinc-800 overflow-y-auto"
                rows="17"
                placeholder={`Start writing your Tweet here... ${String.fromCharCode(
                  10
                )} ${String.fromCharCode(10)} ${String.fromCharCode(
                  10
                )}Skip 3 lines to start a thread.`}
                value={tweet}
                onChange={handleTextareaChange}
              ></textarea>

              {!isThread &&
                tweet.length > MAX_CHARACTERS &&
                !account?.data?.is_blue_tick && (
                  <div
                    data-slot="helper-wrapper"
                    className="group-data-[has-helper=true]:flex p-1 relative flex-col gap-1.5"
                  >
                    <div
                      data-slot="error-message"
                      className="text-tiny text-danger"
                      id="react-aria8498452396-:r6h:"
                    >
                      The Twitter API doesn’t support long posts at the moment.
                      We are working with the Twitter team and hope to add this
                      feature soon.
                    </div>
                  </div>
                )}

              {!isThread && !account?.data?.is_blue_tick && (
                <div
                  data-slot="helper-wrapper"
                  className="group-data-[has-helper=true]:flex p-1 relative flex-col gap-1.5"
                >
                  <div
                    data-slot="description"
                    className="text-xs text-foreground-400"
                  >
                    {tweet.length} / {MAX_CHARACTERS}
                  </div>
                </div>
              )}

              <div className="grid grid-cols-6 gap-2 my-2">
                {mediaPreview.map((preview, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    {preview.type === "video" ? (
                      <video controls className="w-full h-auto rounded-sm">
                        <source src={preview.url} />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Image
                        alt={`Preview ${index}`}
                        src={preview.url}
                        radius="sm"
                        width={50}
                        height={50}
                      />
                    )}
                    {/* <Image
                    width={50}
                    height={50}
                    alt={`Preview ${index}`}
                    src={preview.url}
                    radius="sm"
                  /> */}

                    <Icon
                      icon="carbon:close-outline"
                      size={16}
                      className="mx-auto cursor-pointer"
                      onClick={() => deleteMedia(index)}
                    />
                  </div>
                ))}
              </div>
              {/* <Textarea
              isInvalid={!isThread && tweet.length > MAX_CHARACTERS}
              errorMessage={`The Twitter API doesn’t support long posts at the moment. We are working with the Twitter team and hope to add this feature soon.`}
              ref={textareaRef}
              key="compose"
              variant="faded"
              placeholder={`Start writing your Tweet here... ${String.fromCharCode(
                10
              )} ${String.fromCharCode(10)} ${String.fromCharCode(
                10
              )}Skip 3 lines to start a thread.`}
              className="col-span-12 md:col-span-6 mb-6 md:mb-0"
              value={tweet}
              onChange={handleTextareaChange}
              description={!isThread && `${tweet.length} / ${MAX_CHARACTERS}`}
              minRows={5}
            /> */}
              <Divider orientation="horizontal" />
              <Spacer y={1} />

              <div className="flex items-center justify-around">
                <Tooltip
                  content={
                    isMediaDisabled
                      ? "You can choose either 1 GIF, 1 video or up to 4 images"
                      : "Upload image, video, or GIF"
                  }
                >
                  <label className="cursor-pointer rounded-md p-1 text-gray-600 hover:text-gray-800 hover:bg-gray-200">
                    <Icon
                      icon="mdi:tray-upload"
                      width={17}
                      className={`${
                        isMediaDisabled
                          ? "text-default-400"
                          : "dark:text-default-500"
                      }`}
                      onClick={() => setIsUploadModal(true)}
                    />
                    {/* {!isMediaDisabled && (
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleFileUpload}
                      className="hidden"
                      ref={fileInputRef}
                      multiple
                      accept="image/*,video/*"
                    />
                  )} */}
                  </label>
                </Tooltip>
                {/* Toggle Rich Text */}
                <div
                  className={`p-1 rounded-md text-gray-600 hover:text-gray-800 hover:bg-gray-200 cursor-pointer ${
                    isRichText ? "bg-gray-200" : ""
                  }`}
                  onClick={() => setIsRichText(!isRichText)}
                >
                  <Icon
                    icon="mdi:format-text"
                    width={17}
                    className="dark:text-default-500"
                  />
                </div>
                {/* <Tooltip
                content={
                  isGifDisabled
                    ? "You can choose either 1 GIF, 1 video or up to 4 images"
                    : ""
                }
                isDisabled={!isGifDisabled}
              >
                <div
                  className={`p-1 rounded-md hover:bg-green-200 cursor-pointer ${
                    isGifSelect
                      ? "bg-green-200"
                      : isGifDisabled
                      ? "bg-green-100 dark:bg-green-50"
                      : ""
                  }`}
                  onClick={() => !isGifDisabled && setIsGifSelect(!isGifSelect)}
                >
                  <Icon
                    icon="teenyicons:gif-outline"
                    width={17}
                    className={`${
                      isGifDisabled
                        ? "text-green-400"
                        : "text-green-700 dark:text-green-600"
                    }`}
                  />
                </div>
              </Tooltip> */}

                {/* Toggle Emoji Picker */}
                <div
                  className={`p-1 rounded-md hover:bg-blue-200 cursor-pointer ${
                    isEmoji ? "bg-blue-200" : ""
                  }`}
                  onClick={() => setIsEmoji(!isEmoji)}
                >
                  <Icon
                    icon="mdi:emoji-outline"
                    width={17}
                    className="text-blue-700 dark:text-green-600"
                  />
                </div>

                {/* Toggle AI Assistant */}
                {/* <div
                onClick={() => setAi(!ai)}
                className={`px-3 rounded-full hover:bg-purple-200 cursor-pointer ${
                  ai ? "bg-purple-200" : "bg-purple-100"
                }`}
              >
                <div className="text-sm text-purple-700">AI Assistant</div>
                <RiLightbulbFlashLine size={20} />
              </div> */}

                <Popover
                  placement="right"
                  isOpen={isAssistant}
                  onOpenChange={(open) => setIsAssistant(open)}
                  shadow="lg"
                >
                  <PopoverTrigger>
                    <Button
                      size="sm"
                      color="secondary"
                      variant="ghost"
                      endContent={<Icon icon="tabler:bulb" width={13} />}
                      // onClick={() => setIsAssistant(!isAssistant)}
                    >
                      AI Assistant
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="grid grid-cols-2 gap-4 overflow-y-auto m-2 max-w-[350px]">
                      {fetching ? (
                        <LoaderCircleIcon />
                      ) : (
                        assistents?.map((assistent, idx) => (
                          <Button
                            key={idx}
                            variant="bordered"
                            color="secondary"
                            onClick={() =>
                              handleAssistent(assistent?.id, false)
                            }
                            startContent={<Icon icon={assistent?.icon} />}
                            size="sm"
                          >
                            {assistent?.name}
                          </Button>
                          // <div
                          //   key={idx}
                          //   className="p-2 rounded-md text-purple-700 flex items-center cursor-pointer hover:text-purple-900 text-sm"
                          //   // onClick={() => setAiModal(true)}
                          //   onClick={() => handleAssistent(assistent?.id, false)}
                          // >
                          //   <div className="mr-2">
                          //     {assistent?.icon ? (
                          //       renderIcon(assistent?.icon, idx)
                          //     ) : (
                          //       <IoSend />
                          //     )}
                          //   </div>
                          //   <div>{assistent?.name}</div>
                          // </div>
                        ))
                      )}
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
              {isRichText && (
                <>
                  <Spacer y={2} />
                  <Divider />
                  <Spacer y={2} />
                  <div
                    className="flex items-center justify-between"
                    style={{ marginBottom: "-6px" }}
                  >
                    <div
                      className="text-default-500 font-medium cursor-pointer"
                      onClick={() => handleStyleClick("b")}
                    >
                      <Icon icon="mdi:format-bold" size={18} />
                    </div>
                    <div
                      className="text-default-500 font-medium cursor-pointer"
                      onClick={() => handleStyleClick("i")}
                    >
                      <Icon icon="mdi:format-italic" size={18} />
                    </div>
                    <div
                      className="text-default-500 font-medium cursor-pointer"
                      onClick={() => handleStyleClick("bi")}
                    >
                      <Icon
                        icon="mdi:format-italic"
                        size={18}
                        className="text-gray-800 dark:text-gray-200"
                      />
                    </div>
                    {/* <div
                    className="text-default-500 font-medium cursor-pointer"
                    onClick={handleUnderlineClick}
                  >
                    <Icon icon="mdi:format-underline" size={18} />
                  </div>
                  <div
                    className="text-default-500 font-medium cursor-pointer"
                    onClick={handleStrikeClick}
                  >
                    <Icon icon="mdi:format-strikethrough-variant" size={18} />
                  </div> */}
                    <div
                      className="text-default-500 font-medium cursor-pointer"
                      onClick={() => handleStyleClick("m")}
                    >
                      <Icon icon="mdi:code-tags" size={18} />
                    </div>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button variant="light" isIconOnly>
                          <Icon icon="mdi:keyboard-arrow-down" />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        aria-label="Static Actions"
                        onAction={(key) => handleStyleClick(key)}
                      >
                        <DropdownItem key="w">Fullwidth Text</DropdownItem>
                        <DropdownItem key="c">Script</DropdownItem>
                        <DropdownItem key="g">Gothic</DropdownItem>
                        <DropdownItem key="d">Double Struck</DropdownItem>
                        <DropdownItem key="o">Circled</DropdownItem>
                        <DropdownItem key="q">Squared</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </>
              )}
              <Spacer y={1} />

              <Divider />

              <Spacer y={2} />

              <div className="flex justify-between w-full gap-2">
                <Button
                  size="sm"
                  color="primary"
                  variant="bordered"
                  isLoading={isScheduling}
                  onClick={() => handleScheduleTweet()}
                  endContent={<Icon icon="solar:clock-circle-linear" />}
                  className="w-full"
                >
                  Schedule Tweet
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  variant="solid"
                  isLoading={isTweeting}
                  onClick={() => handleTweetNow()}
                  endContent={!isTweeting && <Icon icon="mdi:send-outline" />}
                  className="w-full"
                >
                  Tweet Now
                </Button>
              </div>
              <Spacer y={2} />
              <Divider />
              <div>
                <div>
                  <EmojiPicker
                    open={isEmoji}
                    lazyLoadEmojis={true}
                    onEmojiClick={handleEmojiClick}
                    theme={
                      localStorage.getItem("theme")
                        ? localStorage.getItem("theme")
                        : "light"
                    }
                    emojiStyle="twitter"
                    width={300}
                  />
                </div>

                {isGifSelect && (
                  <div className="flex justify-center">
                    <ReactGiphySearchbox
                      apiKey="zpgHg1e3m6vBwiwgYAEBdUJnhWjuC7eA" // Required: get your on https://developers.giphy.com
                      onSelect={handleSelectGif}
                      columns={3}
                    />
                  </div>
                )}

                <Spacer y={2} />
                <Button
                  size="sm"
                  variant="light"
                  color="primary"
                  endContent={
                    <Icon
                      icon={
                        isAdvanced
                          ? "mdi:keyboard-arrow-left"
                          : "mdi:keyboard-arrow-right"
                      }
                      width={18}
                    />
                  }
                  onClick={() => setIsAdvanced(!isAdvanced)}
                >
                  Advanced Options
                </Button>
                {isAdvanced && (
                  <div className="px-1 py-2 mb-10">
                    <AdvancedOptions
                      setIsThreadDelay={setIsThreadDelay}
                      isThreadDelay={isThreadDelay}
                      selectedThreadDelay={selectedThreadDelay}
                      setSelectedThreadDelay={setSelectedThreadDelay}
                      isAutoRetweet={isAutoRetweet}
                      setIsAutoRetweet={setIsAutoRetweet}
                      selectedRetweetInterval={selectedRetweetInterval}
                      setSelectedRetweetInterval={setSelectedRetweetInterval}
                      selectedRetweetTimes={selectedRetweetTimes}
                      setSelectedRetweetTimes={setSelectedRetweetTimes}
                      isAutoPlug={isAutoPlug}
                      setIsAutoPlug={setIsAutoPlug}
                      plugLikes={plugLikes}
                      setPlugLikes={setPlugLikes}
                      plugMessage={plugMessage}
                      setPlugMessage={setPlugMessage}
                      isAutoDm={isAutoDm}
                      setIsAutoDm={setIsAutoDm}
                      dmMessage={dmMessage}
                      setDmMessage={setDmMessage}
                      dmLike={dmLike}
                      dmRetweet={dmRetweet}
                      dmReply={dmReply}
                      setDmLike={setDmLike}
                      setDmReply={setDmReply}
                      setDmRetweet={setDmRetweet}
                    />
                  </div>
                )}
                {/* <Popover
                placement="top"
                showArrow={true}
                isOpen={isAdvanced}
                onOpenChange={() => handlePopover()}
              >
                <PopoverTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    color="primary"
                    endContent={
                      <Icon
                        icon={
                          isAdvanced
                            ? "mdi:keyboard-arrow-left"
                            : "mdi:keyboard-arrow-right"
                        }
                        width={18}
                      />
                    }
                  >
                    Advanced Options
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="px-1 py-2 max-w-[280px]">
                    <AdvancedOptions
                      setIsThreadDelay={setIsThreadDelay}
                      isThreadDelay={isThreadDelay}
                      selectedThreadDelay={selectedThreadDelay}
                      setSelectedThreadDelay={setSelectedThreadDelay}
                      isAutoRetweet={isAutoRetweet}
                      setIsAutoRetweet={setIsAutoRetweet}
                      selectedRetweetInterval={selectedRetweetInterval}
                      setSelectedRetweetInterval={setSelectedRetweetInterval}
                      selectedRetweetTimes={selectedRetweetTimes}
                      setSelectedRetweetTimes={setSelectedRetweetTimes}
                      isAutoPlug={isAutoPlug}
                      setIsAutoPlug={setIsAutoPlug}
                      plugLikes={plugLikes}
                      setPlugLikes={setPlugLikes}
                      plugMessage={plugMessage}
                      setPlugMessage={setPlugMessage}
                    />
                  </div>
                </PopoverContent>
              </Popover> */}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default ComposeTweet;
