import React, {
  useState,
  Suspense,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Link, useNavigate } from "react-router-dom";

import ThemedSuspense from "./themed_suspense";
import Logo from "./logo";
import ComposeTweet from "../components/compose_tweet";
import TweetPreview from "../components/tweet_preview";

import { ComposeContext } from "../context/compose_tweet_context";
import AiModal from "./ai_modal";

import { AuthContext } from "../context/auth_context";

import { GetUserAccounts, GetUserByPk } from "../graphql";
import { useQuery } from "urql";
import { getAuth, signOut } from "firebase/auth";

import Intercom from "@intercom/messenger-js-sdk";
import PricingModal from "./pricing_modal";
import UpgradeModal from "./upgrade_modal";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTheme } from "next-themes";

import { backend_url } from "../config";

import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  ScrollShadow,
  Spacer,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  User,
  cn,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import { useMediaQuery } from "usehooks-ts";

import Sidebar from "./sidebar/sidebar";
import NewAccountModal from "./new_account_modal";

function Layout({ children: Children, title }) {
  const authC = getAuth();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMediaQuery("(max-width: 928px)");
  const [isPricing, setIsPricing] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState(null);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  // useEffect(() => {
  //   if (isMobile) {
  //     setIsCollapsed(true);
  //   }
  // }, [isMobile]);

  useEffect(() => {
    const checkForHorizontalScrollbar = () => {
      if (
        document.documentElement.scrollWidth >
        document.documentElement.clientWidth
      ) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    checkForHorizontalScrollbar();

    window.addEventListener("resize", checkForHorizontalScrollbar);

    return () => {
      window.removeEventListener("resize", checkForHorizontalScrollbar);
    };
  }, []);

  const isCompact = isCollapsed;

  const { theme, setTheme } = useTheme();

  const {
    compose,
    setCompose,
    preview,
    setPreview,
    currentPlanDetails,
    setCurrentPlanDetails,
  } = useContext(ComposeContext);

  const { auth, setAuthData, account, setAccountData } =
    useContext(AuthContext);

  Intercom({
    app_id: "xypr5wjd",
    user_id: auth?.data?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: auth?.data?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: auth?.data?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  const [result] = useQuery({
    query: GetUserAccounts,
    variables: {
      user_id: auth?.data?.id,
    },
  });

  const { data } = result;

  // useEffect(() => {
  //   if (data) {
  //     if (data?.twitter_account?.length === 0) {
  //       navigate("/connect-twitter");
  //     } else if (data?.twitter_account?.[0]?.id && !account?.data) {
  //       setAccountData({
  //         id: data?.twitter_account?.[0]?.id,
  //         username: data?.twitter_account?.[0]?.username,
  //         name: data?.twitter_account?.[0]?.me?.name,
  //         profile_pic: data?.twitter_account?.[0]?.profile_pic,
  //         is_blue_tick: data?.twitter_account?.[0]?.verified,
  //       });
  //     }
  //     if (!data?.twitter_account[0]?.user?.plan_id) {
  //       setIsPricing(true);
  //     } else {
  //       setIsPricing(false);
  //     }
  //   }
  // }, [data]);

  const [resultUser] = useQuery({
    query: GetUserByPk,
    variables: {
      id: auth?.data?.id,
    },
  });

  const { data: dataUser } = resultUser;

  const handlePlan = async () => {
    setLoading(true);
    const response = await fetch(
      `${backend_url}/stripe/checkout-new-customer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: auth?.data?.id,
          plan_id: "",
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("result", result);
    if (result.message === "done") {
      setLoading(false);
      toast.success(
        "Your free trial has begun. You have full access to all features for the next 7 days.",
        { autoClose: 5000 }
      );
      if (dataUser?.user_by_pk?.twitter_accounts?.length === 0) {
        setTimeout(() => {
          navigate("/connect-twitter");
        }, 3000);
      }
    }
  };

  const checkUser = () => {
    if (dataUser?.user_by_pk) {
      const freeTrialEndsOn = dataUser?.user_by_pk?.free_trial_ends_on;
      const planId = dataUser?.user_by_pk?.plan_id;

      const freeTrialEndsDate = new Date(freeTrialEndsOn);
      const currentDate = new Date();

      if (planId || freeTrialEndsDate > currentDate) {
        if (dataUser?.user_by_pk?.twitter_accounts?.length === 0) {
          setAccountData(null);
          navigate("/login");
        } else if (
          dataUser?.user_by_pk?.twitter_accounts?.[0]?.id &&
          !account?.data
        ) {
          setAccountData({
            id: dataUser?.user_by_pk?.twitter_accounts?.[0]?.id,
            username: dataUser?.user_by_pk?.twitter_accounts?.[0]?.username,
            name: dataUser?.user_by_pk?.twitter_accounts?.[0]?.me?.name,
            profile_pic:
              dataUser?.user_by_pk?.twitter_accounts?.[0]?.profile_pic,
            is_blue_tick:
              dataUser?.user_by_pk?.twitter_accounts?.[0]?.me?.verified,
          });
        }
        if (dataUser?.user_by_pk?.plan?.name) {
          console.log("user plan", dataUser?.user_by_pk?.plan);
          setPlanName(dataUser?.user_by_pk?.plan?.name);
          setCurrentPlanDetails(dataUser?.user_by_pk?.plan);
        } else {
          setCurrentPlanDetails({
            name: "trial",
            is_tweet_engage: true,
            is_crm: true,
            is_ai_tweet_assistant: true,
            is_tweet_ideas: true,
          });
        }
        // if (!dataUser?.user_by_pk?.twitter_accounts?.[0]?.user_preferences) {
        //   navigate("/onboarding");
        // }
      } else if (!planId && !freeTrialEndsOn) {
        if (!loading) {
          console.log("plan not available start trial");
          handlePlan();
        }
        // setIsPricing(true);
      } else if (freeTrialEndsOn) {
        setIsPricing(false);
        if (!planId && currentDate > freeTrialEndsDate) {
          setIsUpgrade(true);
        }
      }
    }
  };
  useEffect(() => {
    checkUser();
  }, [dataUser]);

  // useEffect(() => {
  //   if (data) {
  // if (data?.twitter_account?.length === 0) {
  //   navigate("/connect-twitter");
  // } else {
  //   const activeAccount = data?.twitter_account?.find(
  //     (account) => account.status === "active"
  //   );
  //   console.log("active account", activeAccount);
  //   if (activeAccount?.id && !account?.data) {
  //     setAccountData({
  //       id: activeAccount.id,
  //       username: activeAccount.username,
  //       name: activeAccount.me?.name,
  //       profile_pic: activeAccount.profile_pic,
  //       is_blue_tick: activeAccount.verified,
  //     });
  //   }
  //   // if (!data?.twitter_account[0]?.user?.plan_id) {
  //   //   setIsPricing(true);
  //   // } else {
  //   //   setIsPricing(false);
  //   // }
  // }
  // }
  // }, [data]);

  const handleAccountSelection = (id) => {
    console.log("key", id);
    const tmp_profile = data?.twitter_account?.find(
      (profile) => profile.id === id
    );

    if (tmp_profile?.status === "disabled") {
      toast.error("Reconnect your account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }
    setAccountData({
      id: tmp_profile?.id,
      name: tmp_profile?.me?.name,
      username: tmp_profile?.username,
      profile_pic: tmp_profile?.profile_pic,
      is_blue_tick: tmp_profile?.me?.verified,
    });
  };

  const toggleMode = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const SidebarItemType = {
    Nest: "nest",
  };

  const sectionItems = [
    {
      key: "overview",
      title: "Overview",
      items: [
        {
          key: "viral",
          url: "/viral-tweets",
          icon: "ph:rocket",
          title: "Viral Tweets",
          endContent: (
            <Icon
              className="text-default-400"
              icon="solar:add-circle-line-duotone"
              width={24}
            />
          ),
        },
        {
          key: "tweet-generator",
          url: "/tweet-generator",
          icon: "system-uicons:write",
          title: "Tweet Generator",
        },
        {
          key: "queue",
          url: "/queue",
          icon: "solar:clock-circle-linear",
          title: "Calendar",
        },
        {
          key: "published",
          url: "/published-tweets",
          icon: "carbon:checkmark-outline",
          title: "Published Tweets",
        },
        {
          key: "analytics",
          url: "/analytics",
          icon: "mdi:chart-line-variant",
          title: "Analytics",
        },
        {
          key: "my-collections",
          url: "/my-collections",
          icon: "mdi:star-outline",
          title: "My Favourite",
        },
      ],
    },
    {
      key: "content",
      title: "Content",
      items: [
        {
          key: "ideas",
          title: (
            <div className="flex items-center gap-1">
              <span>Tweet Ideas</span>
              {!currentPlanDetails?.is_tweet_ideas && (
                <Chip
                  color="secondary"
                  size="sm"
                  classNames={{ base: "h-5 text-[10px]" }}
                >
                  Growth
                </Chip>
              )}
            </div>
          ),
          icon: "icons8:idea",
          type: SidebarItemType.Nest,
          items: [
            {
              key: "industry_search",
              icon: "ph:building",
              url: "/tweet-ideas/industry-search",
              title: (
                <div className="text-xs font-medium text-default-500 group-data-[selected=true]:text-foreground">
                  Industry Search
                </div>
              ),
            },
            {
              key: "keyword_search",
              icon: "codicon:symbol-keyword",
              url: "/tweet-ideas/keyword-search",
              title: (
                <div className="text-xs font-medium text-default-500 group-data-[selected=true]:text-foreground">
                  Keyword Search
                </div>
              ),
            },
          ],
        },
        {
          key: "ai-tweet-assistant",
          title: "AI Tweet Assistant",
          icon: "hugeicons:artificial-intelligence-04",
          type: SidebarItemType.Nest,
          items: [
            {
              key: "scratch",
              icon: "ph:pencil",
              url: "/ai-tweet-assistant/from-scratch",
              title: (
                <div className="text-xs font-medium text-default-500 group-data-[selected=true]:text-foreground">
                  Scratch
                </div>
              ),
            },
            {
              key: "personalised",
              icon: "tabler:puzzle-2",
              url: "/ai-tweet-assistant/personalized-for-you",
              title: (
                <div className="text-xs font-medium text-default-500 group-data-[selected=true]:text-foreground">
                  Personalised
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      key: "workspace",
      title: "Workspace",
      items: [
        {
          key: "twitter-engage",
          url: "/twitter-engage",
          icon: "fluent-mdl2:group",
          title: (
            <div className="flex items-center gap-1">
              <span>Tweet Engage</span>
              {!currentPlanDetails?.is_crm && (
                <Chip
                  color="secondary"
                  size="sm"
                  classNames={{ base: "h-5 text-[10px]" }}
                >
                  Growth
                </Chip>
              )}
            </div>
          ),
        },
        {
          key: "twitter-crm",
          title: (
            <div className="flex items-center gap-1">
              <span>Tweetlio CRM</span>
              {!currentPlanDetails?.is_crm && (
                <Chip
                  color="secondary"
                  size="sm"
                  classNames={{ base: "h-5 text-[10px]" }}
                >
                  Growth
                </Chip>
              )}
            </div>
          ),
          icon: "material-symbols:id-card-outline",
          type: SidebarItemType.Nest,
          items: [
            {
              key: "lists",
              icon: "mdi:contact-outline",
              url: "/twitter-crm/lists",
              title: (
                <div className="text-xs font-medium text-default-500 group-data-[selected=true]:text-foreground">
                  Lists
                </div>
              ),
            },
          ],
        },
        {
          key: "settings",
          url: "/settings",
          icon: "uil:setting",
          title: "Account Settings",
        },
      ],
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      const isPromoShown = localStorage.getItem("isPromoShown");
      if (isPromoShown === null && !planName) {
        setIsNotificationVisible(true);
      }
    }, 3000);
  }, []);

  const handleClose = () => {
    localStorage.setItem("isPromoShown", "true");
    setIsNotificationVisible(false);
  };

  const forceHardRefresh = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      console.log("Invalidating cache..!");
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    } // delete browser cache and hard reload

    window.location.reload();
  };

  const onToggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const handleCompose = useCallback(() => {
    setCompose((prevCompose) => !prevCompose);
  }, []);

  if (loading) {
    return <ThemedSuspense />;
  }

  return (
    <>
      <div className="flex h-dvh w-full dark:bg-zinc-900">
        <div
          className={cn(
            "relative flex h-full w-[259px] flex-col !border-r-small border-divider py-6 px-5 transition-width",
            {
              "w-14 items-center px-2 py-6": isCompact,
            }
          )}
        >
          <div className="flex items-center justify-between">
            <Logo collapsed={isCompact} />

            {!isCompact && (
              <div className="flex items-center justify-end">
                {/* User Menu */}
                <Dropdown showArrow placement="bottom-start">
                  <DropdownTrigger>
                    <User
                      avatarProps={{
                        isBordered: false,
                        src: account?.data?.profile_pic,
                        style: {
                          width: "29px", // Custom width
                          height: "29px", // Custom height
                        },
                      }}
                      className="cursor-pointer"
                    />
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Custom item styles">
                    <DropdownSection aria-label="Profile & Actions">
                      <DropdownItem key="settings">
                        <a
                          href="https://senja.io/p/tweetlio/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Feedback
                        </a>
                      </DropdownItem>
                      <DropdownItem key="dashboard">
                        Profile Automation
                      </DropdownItem>
                      <DropdownItem key="settings">
                        <Link to="/connected-accounts">Team & Permission</Link>
                      </DropdownItem>
                      <DropdownItem key="affiliate">
                        <a
                          href="https://tweetliox.partneroapp.com/login"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Affiliate
                        </a>
                      </DropdownItem>
                      <DropdownItem key="settings">
                        <a
                          href="https://tweetlio.featurebase.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          RoadMap
                        </a>
                      </DropdownItem>
                    </DropdownSection>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>

          <Spacer y={4} />

          <div className="flex flex-col gap-4">
            {isCompact ? (
              <Icon icon="hugeicons:quill-write-02" width={20} />
            ) : (
              <div className="flex justify-center">
                <Button
                  startContent={
                    <Icon icon="hugeicons:quill-write-02" width={18} />
                  }
                  color="primary"
                  variant="shadow"
                  className="custom-btn"
                  onClick={() => setCompose(true)}
                >
                  Write a Tweet
                </Button>
              </div>
            )}
          </div>

          <ScrollShadow className="-mr-6 h-full max-h-full py-4 pr-6">
            <Sidebar
              defaultSelectedKey="viral"
              items={sectionItems}
              isCompact={isCompact}
            />
          </ScrollShadow>

          <Spacer y={2} />

          {!isCompact && !planName?.includes("enterprise") && (
            <Card className="overflow-visible" shadow="sm">
              <CardBody className="items-center text-center">
                <h3 className="text-small py-2 font-medium text-default-700 mb-2">
                  Upgrade to{" "}
                  {planName
                    ? planName.includes("pro")
                      ? "Growth"
                      : planName.includes("growth")
                      ? "Enterprise"
                      : "Pro"
                    : "Pro"}
                  <span aria-label="rocket-emoji" className="ml-2" role="img">
                    🚀
                  </span>
                </h3>
              </CardBody>
              <CardFooter className="absolute -bottom-6 justify-center">
                <Button
                  className="shadow-md px-8 upgrade-btn"
                  color="primary"
                  radius="full"
                  variant="shadow"
                  onClick={() => navigate("/billing")}
                >
                  Upgrade
                </Button>
              </CardFooter>
            </Card>
          )}

          <Spacer y={8} />

          {account?.data && !isCompact && (
            <Dropdown placement="top">
              <DropdownTrigger>
                <Button
                  className="mb-2 h-16 items-center justify-between"
                  variant="light"
                >
                  <User
                    avatarProps={{
                      style: {
                        width: "29px", // Custom width
                        height: "29px", // Custom height
                      },
                      isBordered: false,
                      src: account?.data?.profile_pic,
                    }}
                    className="justify-start transition-transform"
                    description={
                      <span style={{ fontSize: "10px" }}>
                        @{account?.data?.username}
                      </span>
                    }
                    name={
                      <div className="flex gap-1 items-center w-full text-tiny">
                        {account?.data?.name}{" "}
                        {account?.data?.is_blue_tick && (
                          <Icon
                            icon="mage:verified-check-fill"
                            className="text-blue-500"
                            width={18}
                          />
                        )}
                      </div>
                    }
                  />
                  <Icon
                    className="text-default-400"
                    icon="lucide:chevrons-up-down"
                    width={14}
                  />
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Account switcher" variant="flat">
                <DropdownSection
                  showDivider
                  title="Account Switcher"
                  aria-label="Profile & Actions"
                >
                  {data?.twitter_account?.map((profile, index) => (
                    <DropdownItem
                      key={profile.id}
                      textValue={profile.username}
                      onClick={() => handleAccountSelection(profile.id)}
                    >
                      <div className="flex items-center gap-x-3">
                        <Avatar
                          alt={profile.username}
                          classNames={{
                            base: "flex-shrink-0 w-8 h-8",
                            img: "transition-none",
                          }}
                          src={profile.profile_pic}
                        />
                        <div className="flex flex-col">
                          <div className="flex gap-1 items-center w-full text-tiny font-medium">
                            {profile?.me?.name}{" "}
                            {profile?.me?.verified && (
                              <Icon
                                icon="mage:verified-check-fill"
                                className="text-blue-500"
                                width={16}
                              />
                            )}
                          </div>
                          <p className="text-tiny text-default-400">
                            @{profile.username}
                          </p>
                        </div>
                      </div>
                    </DropdownItem>
                  ))}
                  <DropdownItem key={111} textValue="sdf">
                    <div className="flex items-center gap-x-3">
                      <div className="flex flex-col">
                        <Chip
                          startContent={
                            <Icon icon="zondicons:add-outline" size={18} />
                          }
                          variant="light"
                          color="success"
                          onClick={() => setIsNewAccount(true)}
                        >
                          <div className="font-medium text-xs">
                            Connect new account
                          </div>
                        </Chip>
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownSection>

                <DropdownSection title="Actions" aria-label="Help & Feedback">
                  <DropdownItem key="profile" className="h-13 gap-2" isReadOnly>
                    <p className="font-semibold text-xs">Signed in as</p>
                    <p className="font-normal text-xs text-blue-600 dark:text-blue-500">
                      {auth?.data?.email}
                    </p>
                  </DropdownItem>
                  <DropdownItem
                    key="logout"
                    onClick={() => {
                      localStorage.removeItem("authData");
                      localStorage.removeItem("twitterAccount");
                      setAuthData(null);
                      setAccountData(null);
                      signOut(authC);
                    }}
                  >
                    Log Out
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
          )}

          <div className="mt-auto flex flex-col">
            <div className="flex items-center">
              {planName && !isCompact && (
                <Chip color="secondary" className="capitalize" size="sm">
                  {planName?.split("-")?.[0]}
                </Chip>
              )}

              {theme === "dark" ? (
                <Icon
                  icon="ic:outline-light-mode"
                  width={16}
                  className="ml-2 cursor-pointer"
                  onClick={toggleMode}
                />
              ) : (
                <Icon
                  icon="ph:moon-fill"
                  className="ml-2 cursor-pointer"
                  width={16}
                  onClick={toggleMode}
                />
              )}
              <Icon
                icon="tabler:reload"
                className="mx-2 cursor-pointer"
                onClick={() => forceHardRefresh()}
                width={16}
              />
              {!isCompact && (
                <div className="ml-2 text-xs text-default-600 flex justify-between">
                  Version: {global.appVersion}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-[90%] flex-1 flex-col p-2">
          <header className="flex items-center gap-3 rounded-medium border-small border-divider p-3 justify-between">
            <div className="flex items-center gap-3">
              <Button
                isIconOnly
                size="sm"
                color="primary"
                variant="shadow"
                onPress={onToggle}
              >
                <Icon
                  icon={
                    isCompact
                      ? "mingcute:arrow-to-right-line"
                      : "mingcute:arrow-to-left-line"
                  }
                  width={20}
                />
              </Button>
              <h2 className="text-small font-bold text-default-700">{title}</h2>
            </div>

            <Button
              isIconOnly
              size="sm"
              color="primary"
              variant="shadow"
              onPress={handleCompose}
            >
              <Icon
                icon={
                  compose
                    ? "mingcute:arrow-to-right-line"
                    : "hugeicons:quill-write-02"
                }
                width={20}
              />
            </Button>
          </header>
          {isNotificationVisible && !planName && (
            <div className="flex w-full rounded-md mt-1 items-center gap-x-3 border-b-1 border-divider bg-primary px-6 py-2 sm:px-3.5 sm:before:flex-1">
              <p className="text-tiny text-primary-foreground">
                🚀 New Growth Plan Price: Just $49! Elevate Your Twitter
                Strategy Today with Tweetlio's Powerful Tools – No Discounts
                Needed!
              </p>
              <Link to="/billing">
                <Button
                  color="default"
                  variant="faded"
                  endContent={
                    <Icon
                      className="flex-none outline-none transition-transform group-data-[hover=true]:translate-x-0.5 [&>path]:stroke-[2]"
                      icon="solar:arrow-right-linear"
                      width={16}
                    />
                  }
                  size="sm"
                >
                  Explore
                </Button>
              </Link>
              <div className="flex flex-1 justify-end">
                <Button
                  isIconOnly
                  aria-label="Close Banner"
                  className="-m-1"
                  size="sm"
                  variant="light"
                  onClick={() => handleClose()}
                >
                  <Icon
                    aria-hidden="true"
                    className="text-primary-foreground"
                    icon="lucide:x"
                    width={20}
                  />
                </Button>
              </div>
            </div>
          )}

          <main className="mt-1 h-[90%] w-full overflow-y-auto">
            <div className="flex h-full w-full flex-col gap-4 rounded-medium border-small border-divider">
              <ScrollShadow>
                <Children />
              </ScrollShadow>
            </div>
          </main>
        </div>
        <div className="relative">
          {preview && (
            <div
              className={cn(
                "absolute right-[323px] flex w-[317px] max-w-[317px] flex-1 flex-col transition-[transform,opacity,margin] duration-250 ease-in-out z-50"
              )}
            >
              <TweetPreview close={setPreview} />
            </div>
          )}

          {compose && (
            <div
              className={cn(
                "right-0 flex w-[323px] flex-1 flex-col !border-l-small border-divider transition-[transform,opacity,margin] duration-250 ease-in-out"
              )}
            >
              <ComposeTweet close={setCompose} />
            </div>
          )}
        </div>
      </div>

      <Suspense fallback={<ThemedSuspense />}>
        <PricingModal isPricing={isPricing} setIsPricing={setIsPricing} />
        <UpgradeModal isUpgrade={isUpgrade} setIsUpgrade={setIsUpgrade} />
        <NewAccountModal
          isNewAccount={isNewAccount}
          setIsNewAccount={setIsNewAccount}
        />

        <AiModal />
      </Suspense>
    </>
  );
}

export default Layout;
