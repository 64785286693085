import React, { useContext, useEffect } from "react";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import { AuthContext } from "./context/auth_context";
import Layout from "./components/layout";

const PrivateRoute = ({ children, title, ...rest }) => {
  const { auth } = useContext(AuthContext);
  const { loading } = auth;

  const location = useLocation();

  useEffect(() => {
    // Capture the referral code from the URL
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("ref");

    if (referralCode) {
      // Store the referral code in localStorage or send it to context
      localStorage.setItem("partner_referral_code", referralCode);
      console.log("Referral code captured", referralCode);
    }
  }, [location]);

  return (
    <>
      {loading ? (
        <Routes>
          <Route
            {...rest}
            render={() => {
              return <p>Loading...</p>;
            }}
          />
        </Routes>
      ) : auth.data ? (
        <Layout children={children} title={title} />
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export default PrivateRoute;
