import React, { createContext, useState } from "react";

export const ComposeContext = createContext({});

const ComposeProvider = ({ children }) => {
  const [tweet, setTweet] = useState("");
  const [compose, setCompose] = useState(true);
  const [preview, setPreview] = useState(false);
  const [aiModal, setAiModal] = useState(false);
  const [aiTweet, setAiTweet] = useState("");
  const [loading, setLoading] = useState(true);
  const [retry, setRetry] = useState(0);
  const [conversation, setConversation] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [limitWarning, setLimitWarning] = useState(false);
  const [isThread, setIsThread] = useState(false);
  const [isRegenerate, setIsRegenerate] = useState(false);
  const [regClicked, setRegClicked] = useState(false);
  const [mediaPreview, setMediaPreview] = useState([]);
  const [threadMedia, setThreadMedia] = useState([]);
  const [currentPlanDetails, setCurrentPlanDetails] = useState();
  const [selectedMedia, setSelectedMedia] = useState([]);

  return (
    <ComposeContext.Provider
      value={{
        tweet,
        setTweet,
        compose,
        setCompose,
        preview,
        setPreview,
        aiModal,
        setAiModal,
        aiTweet,
        setAiTweet,
        loading,
        setLoading,
        retry,
        setRetry,
        setConversation,
        conversation,
        cancel,
        setCancel,
        limitWarning,
        setLimitWarning,
        isThread,
        setIsThread,
        isRegenerate,
        setIsRegenerate,
        regClicked,
        setRegClicked,
        mediaPreview,
        setMediaPreview,
        threadMedia,
        setThreadMedia,
        currentPlanDetails,
        setCurrentPlanDetails,
        selectedMedia,
        setSelectedMedia,
      }}
    >
      {children}
    </ComposeContext.Provider>
  );
};

export default ComposeProvider;
